import React from 'react';

const Closed = () => {
  return (
    <section className='closure'>
      <h2 className='section__title'>Holiday Season Closure</h2>
      <h3 className='section__subtitle'>Our office will be closed from December 23rd, 2024, to February 3rd, 2025. 
        However, we are still accepting bookings via email <a href="mailto:dressmakingstudio@gmail.com"><u>dressmakingstudio@gmail.com</u></a>. 
        We apologize for any inconvenience caused.</h3>
      </section>
  )
}

export default Closed